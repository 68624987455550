import { NgModule } from '@angular/core';
import { provideState } from '@ngrx/store';
import {notificationReducer, NOTIFICATIONS_COMPONENT} from "@revolve-app/app/shared/notifier/store/notification.reducer";

@NgModule({
  providers: [
    provideState(NOTIFICATIONS_COMPONENT, notificationReducer)
  ]
})
export class NotificationModule { }
