import { Component } from '@angular/core';
import {
  NotificationItem,
  NotifierItemComponent
} from '@revolve-app/app/shared/notifier/notifier-item/notifier-item.component';
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import { selectNotifications } from "@revolve-app/app/shared/notifier/store/notification.reducer";
import {AsyncPipe, NgIf} from "@angular/common";
import {NotificationModule} from "@revolve-app/app/shared/notifier/store/notification.module";
@Component({
  selector: 'app-notifier-container',
  templateUrl: './notifier.component.html',
  standalone: true,
  imports: [
    NotifierItemComponent,
    NotificationModule,
    AsyncPipe,
    NgIf,
  ],
  providers:[]
})
export class NotifierComponent {

  notifications$: Observable<NotificationItem[]>;
  constructor(private storeService: Store) {
    this.notifications$ = this.storeService.select(selectNotifications);
  }
}
