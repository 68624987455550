import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as AuthActions from './notification.actions';
import {NotificationItem} from "@revolve-app/app/shared/notifier/notifier-item/notifier-item.component";
import {randomString} from "@revolve-app/app/core/utils/utils";

export const NOTIFICATIONS_COMPONENT = 'notifications_component';
export interface NotificationsState {
  notifications: NotificationItem[]
}

export const signInInitialState: NotificationsState = {
  notifications: []
};

export const notificationReducer = createReducer(
  signInInitialState,
  on(AuthActions.addNotification, (state,{ payload }) => ({ notifications: [...state.notifications, {...payload, id: randomString(10)}]})),
  on(AuthActions.removeNotification, (state, { payload }) => ({ notifications: [...state.notifications.filter((item)=> item.id !== payload.id)]})),
);

export function notifications(state: NotificationsState , action: Action) {
  return notificationReducer(state, action);
}
export const selectNotificationsState = createFeatureSelector<NotificationsState>(NOTIFICATIONS_COMPONENT);
export const selectNotifications = createSelector(
  selectNotificationsState,
  (state: NotificationsState) => state?.notifications
);
